import React from "react";
import { useSearchParams } from "react-router-dom";
import { MinusOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Collapse, theme } from "antd";
import { useFilterContext } from "context/FilterContext/Filter.context";
import { useTranslations } from "translations/useTranslations";

import { getDefaultActiveKey } from "./helpers/getDefaultActiveKey";

import { Preloader, PreloaderType } from "ui/Preloader";

import { componentsMap } from "./components/componentsMap";
import { useFacetsSearchParams } from "./hooks/useFacetsSearchParams";
import { useInitialFacetsServiceLoading } from "./hooks/useInitialFacetsServiceLoading";
import { FacetsModuleContextProvider } from "./FacetsModule.context";
import { FacetsModuleType } from "./FacetsModule.types";

import styles from "./FacetsModule.module.scss";

export const FacetsModule = React.memo<FacetsModuleType>((props) => {
  const { facetsKey, generateApiUrl, urlQueryService, allowedKeys = [] } = props;
  const { token } = theme.useToken();
  const translations = useTranslations();
  useSearchParams();
  const { facetSectionsConfiguration } = useFilterContext();
  const { isFacetsServiceLoading, facetsService } = useInitialFacetsServiceLoading(
    facetsKey,
    urlQueryService,
    facetSectionsConfiguration
  );

  const visibleFacetSectionsConfiguration = React.useMemo(
    () => facetSectionsConfiguration.filter(
      (sectionConfig) => !sectionConfig.meta?.isHidden && !sectionConfig.meta?.isImmutable
    ),
    [facetSectionsConfiguration]
  );

  const defaultOpenedFacets = React.useMemo(
    () => getDefaultActiveKey(visibleFacetSectionsConfiguration, facetsService),
    [visibleFacetSectionsConfiguration, facetsService]
  );
  const [openedFacets, setOpenedFacets] = React.useState<string[]>(defaultOpenedFacets);

  const { facetSectionChange, resetFacetsSearchParams } = useFacetsSearchParams(facetsService, urlQueryService);

  const onCollapseChange = React.useCallback(
    (keys: string | string[]) => setOpenedFacets(Array.isArray(keys) ? keys : [keys]),
    [setOpenedFacets]
  );

  if (isFacetsServiceLoading) {
    return (
      <div className="w-full py-8">
        <Preloader type={PreloaderType.SPINNER_BLOCK} />
      </div>
    );
  }

  return (
    <div className="md:sticky md:top-2 flex flex-col w-full h-full">
      <div className="flex-grow overflow-y-auto">
        <FacetsModuleContextProvider
          urlQueryService={urlQueryService}
          facetsService={facetsService}
          facetSectionChange={facetSectionChange}
          generateApiUrl={generateApiUrl}
          allowedKeys={allowedKeys}
        >
          <Collapse
            expandIconPosition={"end"}
            defaultActiveKey={defaultOpenedFacets}
            expandIcon={({ isActive }) => (isActive ? <MinusOutlined /> : <PlusOutlined />)}
            className={styles.collapse}
            onChange={onCollapseChange}
          >
            {visibleFacetSectionsConfiguration.map((facetSectionElement) => {
              const FacetComponent = componentsMap[facetSectionElement.type];
              return (
                <FacetComponent
                  key={facetSectionElement.key}
                  facetSectionElement={facetSectionElement}
                  isFacetOpen={openedFacets.includes(facetSectionElement.key)}
                />
              );
            })}
          </Collapse>
        </FacetsModuleContextProvider>
      </div>
      <div
        className="sticky bottom-0 w-full py-2 border-solid border-0 border-t border-t-black/5"
        style={{ backgroundColor: token.colorBgContainer }}
      >
        <Button type="ghost" icon={<SyncOutlined />} onClick={resetFacetsSearchParams} className="w-full">
          {translations["item.table.facets.reset"]}
        </Button>
      </div>
    </div>
  );
});
